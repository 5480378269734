<template >
  
    <v-container >
      <v-row>
        <v-col md="12" class="d-flex justify-end">
          <inventory-action @done="fetchInventories" />
        </v-col>
        <spinner v-if="fetching" />
        <template v-else>
            <h2 v-if="!items.length">{{ $t('There Are No Inventories') }}</h2>
            <template v-else>
              <v-col md="4" v-for="item in items" :key="item.id">
                <div class="inventory-card">
                  <h3>{{ item.name }}</h3>
                  <span>{{ item.desc ? item.desc :  '-'}}</span>
                  <div>
                    <view-inventory  :inventory="item" />
                    <inventory-action
                      :inventory="item"
                      @done="fetchInventories"
                      :isNew="false"
                    />
                    <delete-inventory
                      @done="fetchInventories"
                      :inventory="item"
                    />
                  </div>
                </div>
              </v-col>
            </template>
        </template>
        <v-col md="12">
          <v-pagination
          v-model="page"
          @input="fetchInventories"
          :length="Math.ceil(total / 10)"
          circle
        ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  
</template>

<script>
import deleteInventory from "./delete-inventory.vue";
import inventoryAction from "./inventory-action.vue";
import viewInventory from "./view-inventory.vue";
import axios from "axios";
import spinner from "../../../../components/base/spinner.vue";

export default {
  components: { inventoryAction, viewInventory, deleteInventory, spinner },
  data() {
    return {
      items: [],
      fetching: false,
      page: 1,
      total: 0,
    };
  },
  methods: {
    async fetchInventories() {
      try {
        this.fetching = true;
        const result = await axios.get("/inventories", {
          params: {
            size: 10,
            page: this.page,
          },
        });
        this.items = result.data.data;
        this.total = result.data.meta.total;
      } catch (error) {
        console.log(error);
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.fetchInventories();
  },
};
</script>

<style scoped>
.inventory-card {
  border: 1px solid var(--bg-color--font);
  border-radius: 25px;
  padding: 15px;
}

</style>
