<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="red">mdi-delete</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title class="text-h5  lighten-2"> {{ $t('delete') }}</v-card-title>
      <v-card-text class="my-2">
        {{ $t('are you sure you want delete') }} {{ inventory.name }}؟
        <v-row>
          <v-col md="12">
            <v-btn @click="deleteInventory" color="red" class="mt-3" dark block> {{ $t('delete') }} </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    inventory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  methods: {
    async deleteInventory() {
      try {
        this.deleting = true;
        await axios.delete(`inventories/${this.inventory.id}`);
        this.dialog = false;
        this.$emit("done");
      } finally {
        this.deleting = false;
      }
    },
  },
};
</script>

<style></style>
