<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" v-if="isNew">
        {{ $t('Add Inventory') }}
      </v-btn>
      <v-btn color="blue" icon v-else v-bind="attrs" v-on="on">
        <v-icon class="ml-1">mdi-pen</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ isNew ? $t('Add Inventory') : $t('Edit Inventory') }}</v-card-title>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col md="12">
              <v-text-field v-model="formData.name" :label="$t('Inventory Name')" required
                :rules="[nameRules]"></v-text-field>
            </v-col>
            <v-col md="12">
              <v-text-field v-model="formData.in_serial" :label="$t('Receiving Number')"></v-text-field>
            </v-col>
            <v-col md="12">
              <v-text-field v-model="formData.out_serial" :label="$t('Delivery Number')"></v-text-field>
            </v-col>
            <v-col md="12">
              <v-textarea v-model="formData.desc" :label="$t('The Description')" auto-grow outlined rows="3" row-height="15"></v-textarea>
            </v-col>
            <v-col md="12">
              <v-btn :loading="submitting" @click="submit" block color="secondary">{{ isNew ? $t('add') : $t('edit') }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    inventory: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      validationForm: null,
      nameRules: (v) => !!v || "هذا الحقل مطلوب",
      submitting: false,
      formData: {
        name: null,
        in_serial: null,
        out_serial: null,
        desc: null,
      },
    };
  },
  methods: {
    async submit() {
      const res = this.$refs.form.validate();
      console.log("123", res);
      if (res) {
        try {
          this.submitting = true;
          const temp = {
            name: this.formData.name,
            desc: this.formData.desc ? this.formData.desc : null,
            in_serial: this.formData.in_serial,
            out_serial: this.formData.out_serial,
          }
          if (this.isNew) {
            await axios.post("/inventories", temp);
          } else {
            await axios.put(`/inventories/${this.inventory.id}`, temp);
          }
          this.dialog = false;
          Object.keys(this.formData).forEach((k) => {
            this.formData[k] = null;
          });
          this.$emit("done");
        } finally {
          this.submitting = false;
        }
      }
    },
  },
  created() {
    if (!this.isNew) {
      this.formData.name = this.inventory.name;
      this.formData.desc = this.inventory.desc;
      this.formData.in_serial = this.inventory.in_serial;
      this.formData.out_serial = this.inventory.out_serial;
    }
  },
};
</script>

<style></style>
