<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="var(--icon-show-col)" class="ml-1">mdi-eye</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        استعراض مستودع</v-card-title
      >
      <v-container>
        <v-row>
          <v-col md="12">
            <v-text-field readonly v-model="inventory.name" :label="$t('Inventory Name')"></v-text-field>
          </v-col>
          <v-col md="12">
              <v-text-field
                v-model="inventory.in_serial"
                :label="$t('Receiving Number')"
                readonly
               
              ></v-text-field>
            </v-col>
            <v-col md="12">
              <v-text-field
                v-model="inventory.out_serial"
                :label="$t('Delivery Number')"
                readonly
                
              ></v-text-field>
            </v-col>
          <v-col md="12">
            <v-textarea
              v-model="inventory.desc"
              :label="$t('The Description')"
              auto-grow
              readonly
              outlined
              rows="3"
              row-height="15"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    inventory: {
        type: Object,
        required: true,
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style></style>
